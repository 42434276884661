<template>

  <!-- Opps Details Panel -->
  <div id="opps-details-panel">

    <!-- Opp Tile -->
    <OppTile
      :opp="opp"
      class="mb-4"
    >
      <template #actions>

        <!-- Opp Tools -->
        <OppTools />

      </template>

      <template #footer>
        <v-spacer />

        <!-- Request Button -->
        <OppRequest
          v-if="opp.stage == 'available'"
          :opp="opp"
          class="my-2"
        />

        <!-- Confirm Button -->
        <OppConfirm
          v-if="confirmable"
          :opp="opp"
          class="my-2"
          @confirm="handleConfirm(opp)"
        />
      </template>

    </OppTile>

    <!-- Comments Content -->
    <CommentManager :source="opp" />

  </div>
</template>

<script>

// Components
import CommentManager from '@/components/panels/comments/CommentManager'
import OppConfirm from '@/components/widgets/Opps/OppConfirm'
import OppRequest from '@/components/widgets/Opps/OppRequest'
import OppTile from '@/components/widgets/Opps/OppTile'
import OppTools from '@/components/Opps/OppTools'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('opps')

export default {
  name: 'OppDetailsPanel',
  // -------------
  // Components ==
  // -------------
  components: {
    CommentManager,
    OppConfirm,
    OppRequest,
    OppTile,
    OppTools
  },
  // -------
  // Data ==
  // -------
  data() {
    return {
      iframeHeight: '300px'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'opp',
      'oppSource'
    ]),
    // UI
    confirmable () {
      return this.opp.stage === 'proposed' && (this.opp.assignment_slug == this.$user.slug || this.$user.isAdmin)
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'confirmOpp',
      'saveOpp',
      'sendOpp'
    ]),
    // Handlers
    handleConfirm (opp) {
      this.confirmOpp(opp)
    },
    handleSave () {
      this.saveOpp()
    },
    handleSend (stage) {
      this.sendOpp(stage)
    }
  }
}
</script>
