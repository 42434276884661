<template>

  <!-- Opp Confirm Widget (Button) -->
  <div
    v-if="showButton"
    class="opp-confirm-widget"
  >

    <!-- Confirm Button -->
    <j-button
      label="Confirm"
      icon="check"
      :processing="opp.saving"
      @action="handleConfirm"
    />

  </div>

  <!-- Opp Confirm Widget (Icon) -->
  <div
    v-else
    class="opp-confirm-widget"
  >
    <!-- Confirm Icon -->
    <j-icon
      class="secondary--text"
      icon="check"
      @click="handleConfirm"
    />
  </div>
</template>

<script>

export default {
  name: 'OppConfirmWidget',
  // -------------
  // Properties ==
  // -------------
  props: {
    opp: {
      type: Object,
      default: () => {}
    },
    buttonType: {
      type: String,
      default: 'button'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // UI
    showButton () {
      return this.buttonType === 'button'
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Handlers
    handleConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>
