<template>

  <!-- Opp Tools -->
  <div class="opp-tools">

    <!-- Opp Tools -->
    <j-menu
      icon="cog"
      button-type="icon"
      button-class="float-right"
      class="staff-tools-viewing"
    >

        <!-- Share to FB -->
        <share-network
          v-if="userCanShare"
          network="facebook"
          :url="opp.public_opp"
          :title="opp.title"
          :description="opp.description"
        >
          <j-menu-item
            :icon="['fab', 'facebook']"
            label="Share to FB"
          />
        </share-network>

        <!-- Recommend -->
        <j-menu-item
          v-if="userCanRecommend"
          icon="bullhorn"
          label="Recommend"
          @click="handleRecommend"
        />

        <!-- Link -->
        <j-menu-item
          v-if="showPermalink"
          icon="link"
          label="Permalink"
          :to="{ name: 'opps-details', params: { tag19: opp.tag19 }}"
        />

        <!-- Edit -->
        <j-menu-item
          v-if="showEdit && userCanEdit"
          icon="edit"
          label="Edit"
          @click="handleEdit"
        />

        <!-- Retire -->
        <j-menu-item
          v-if="userCanSend('retired')"
          icon="inbox"
          label="Retire"
          @click="handleSend('retired')"
        />

        <!-- Repost -->
        <j-menu-item
          v-if="userCanSend('available')"
          icon="inbox"
          label="Repost"
          @click="handleSend('available')"
        />

        <!-- Delete -->
        <j-menu-item
          v-if="userCanSend('deleted')"
          icon="times"
          label="Delete"
          @click="handleSend('deleted')"
        />

        <!-- Restore -->
        <j-menu-item
          v-if="userCanSend('feed')"
          icon="undo"
          label="Restore"
          @click="handleSend('available')"
        />

        <!-- Republish -->
        <j-menu-item
          v-if="userCanSend('retired')"
          icon="recycle"
          label="Republish"
          @click="handleRepublish()"
        />

        <!-- News Draft -->
        <j-menu-item
          v-if="userCanEdit"
          icon="newspaper"
          label="Save as News"
          :to="{ name: 'opps-news-draft' }"
        />

    </j-menu>

    <!-- Edit Button -->
    <j-button
      type="icon"
      icon="edit"
      :to="{ name: 'opps-edit' }"
      class="float-right"
    />

  </div>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('opps')

export default {
  name: 'OppDetails',
  // -------
  // Data ==
  // -------
  data() {
    return {
      tabs: 'details',
      iframeHeight: '100%'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState ([
      'opp'
    ]),
    // UI
    showPermalink () {
      return this.$route.name !== 'opps-details'
    },
    showEdit () {
      return this.$route.name !== 'opps-details'
    },
    // Policy
    // TODO - Normalize policy
    userCanEdit () {
      return this.$user.isAdmin
    },
    userCanShare () {
      return this.$user.isAdmin
    },
    userCanRecommend () {
      return true
    },
    userCanFeature () {
      return this.$user.isAdmin || !this.opp.get('featured')
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'republishOpp',
      'sendOpp'
    ]),
    // Mutations
    ...mapMutations([
      'NEW_DRAFT',
      'NEW_RECOMMENDATION'
    ]),
    // Policy
    userCanSend (stage) {
      return this.userCanEdit && this.opp.canSend(stage)
    },
    // Handlers
    handleRecommend () {
      this.NEW_RECOMMENDATION()
      this.$dialog.open('recommend-opp')
    },
    handleEdit () {
      this.SET_OPP_MODE('edit')
    },
    handleNews () {
      this.NEW_DRAFT()
      this.SET_OPP_MODE('news')
    },
    handleSend (stage) {
      this.sendOpp(stage)
    },
    handleRepublish () {
      this.republishOpp().then(() => {
        this.$store.commit('addNotice', { message: `The opportunity was republished.` })
      })
    }
  }
}
</script>

<style lang="css">

a.share-network-facebook {
  text-decoration: none !important;
}

</style>
